/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useEffect, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '../../config/api';
import { useToast } from '../../hooks/ToastContext';

import Menu from '../../components/Menu';
import Table from '../../components/Table';
import Th from '../../components/Table/Th';
import Td from '../../components/Table/Td';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Select from '../../components/Select';

import add from '../../assets/add.svg';
import search from '../../assets/search.svg';
import view from '../../assets/view.svg';
import edit from '../../assets/edit.svg';
import save from '../../assets/save.svg';
import loading from '../../assets/loading01.svg';
import page_left from '../../assets/page_left.svg';
import page_right from '../../assets/page_right.svg';
import market from '../../assets/market.svg';
import letras from '../../assets/letras.svg';
import numeros from '../../assets/numeros.svg';
import door from '../../assets/door.svg';
import food_support from '../../assets/food_support.svg';

import { User } from '../../types/Users';
import { Household as HouseholdProps } from '../../types/Household';

import {
  Container,
  Widget,
  Top,
  Title,
  Search,
  ModalBox,
  Button,
  Close,
  Loading,
  PageBox,
  Tdid,
} from './styles';
import CheckBox from '../../components/CheckBox';

const schema = yup.object().shape({
  name: yup.string().required('* o nome é obrigatório'),
  holder: yup.string().required('* o titular é obrigatório'),
  kinship: yup.string().required('* o parentesco é obrigatório'),
  food_support: yup.boolean(),
});

type UserOption = {
  value: string;
  label: string;
};

type HouseholdFormData = {
  name: string;
  holder: string;
  kinship: string;
  food_support: boolean;
};

let debounce: NodeJS.Timeout;

const Household: React.FC = () => {
  const ROWLIMIT = 10;
  const [households, setHouseholds] = useState([] as HouseholdProps[]);
  const [householdsList, setHouseholdsList] = useState([] as HouseholdProps[]);
  const [displayHouseholds, setDisplayHouseholds] = useState(
    [] as HouseholdProps[],
  );
  const [optionUsers, setOptionUsers] = useState([] as UserOption[]);
  const [displayModal, setDisplayModal] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [householdToEdit, setHouseholdToEdit] = useState({} as HouseholdProps);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { addToast } = useToast();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm<HouseholdFormData>({
    resolver: yupResolver(schema),
  });

  const householdsToDisplay = useCallback((pageData, array) => {
    const arrayTemp = [];

    for (
      let index = ROWLIMIT * (pageData - 1);
      index <= pageData * ROWLIMIT - 1;
      index++
    ) {
      if (array[index]) {
        arrayTemp.push(array[index]);
      }
    }

    setDisplayHouseholds(arrayTemp);
  }, []);

  const handleSeach = (e: React.FormEvent<HTMLInputElement>) => {
    clearTimeout(debounce);
    e.preventDefault();

    const searchText = e.currentTarget.value.toLowerCase();
    searchText
      ? (debounce = setTimeout(() => {
          const arrayTemp = households.filter(
            (household) =>
              household.name.toLowerCase().includes(searchText) ||
              household.int_num.toLowerCase().includes(searchText) ||
              household.user.name.toLowerCase().includes(searchText),
          );
          setHouseholdsList(arrayTemp);
          householdsToDisplay(1, arrayTemp);
          setPages(Math.ceil(arrayTemp.length / ROWLIMIT));
        }, 500))
      : (debounce = setTimeout(() => {
          setHouseholdsList(households);
          householdsToDisplay(1, households);
          setPages(Math.ceil(households.length / ROWLIMIT));
        }, 500));
  };

  const getHouseholdOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('household');

      setHouseholds(response.data);
      setHouseholdsList(response.data);
      setPages(Math.ceil(response.data.length / ROWLIMIT));
      householdsToDisplay(page, response.data);
      setIsLoading(false);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter agregados',
        description: { code: 306 },
      });
    }
  }, [householdsToDisplay, page, addToast]);

  const handlePage = (data: number) => {
    if (page + data > 0 && page + data <= pages) {
      setPage((prevState) => prevState + data);
      householdsToDisplay(page + data, householdsList);
    }
  };

  const getUserOptions = useCallback(async () => {
    try {
      const response = await api.get('user');

      const userOption = response.data.map((user: User) => {
        return {
          value: user.id,
          label: user.name,
        };
      });

      setOptionUsers(userOption);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro a obter utentes',
        description: { code: 206 },
      });
    }
  }, [addToast]);

  const closeModal = () => {
    setDisplayModal(false);
    setEditForm(false);
    reset({ name: '', kinship: '', holder: '' });
  };

  const handleEdit = (household: HouseholdProps) => {
    setHouseholdToEdit(household);
    setValue('name', household.name);
    setValue('kinship', household.kinship);
    setValue('holder', household.user.id);
    setValue('food_support', household.food_support);
    setEditForm(true);
    setDisplayModal(true);
  };

  const handleOnSave = async (data: HouseholdFormData) => {
    try {
      if (editForm) {
        const response = await api.put(`household/${householdToEdit.id}`, data);
        if (response.data) {
          getHouseholdOptions();
          addToast({
            type: 'success',
            title: 'Edição de agregado',
            description: { code: 308 },
          });
        }
      } else {
        const response = await api.post('household', data);
        if (response.data) {
          getHouseholdOptions();
          setDisplayModal(false);
          addToast({
            type: 'success',
            title: 'Criação de agregado',
            description: { code: 308 },
          });
        }
      }
      closeModal();
    } catch (e: any) {
      addToast({
        type: 'error',
        title: editForm
          ? 'Erro a edição do agregado'
          : 'Erro a criação do agregado',
        description:
          e.response && e.response.data ? e.response.data : { code: 1000 },
      });
    }
  };

  const handleView = (id: string) => {
    history.push({
      pathname: 'agregados/ver',
      state: {
        id,
      },
    });
  };

  useEffect(() => {
    getHouseholdOptions();
    getUserOptions();
  }, []);

  return (
    <>
      <Menu />
      <Container>
        <Widget>
          <Top>
            <Title>
              <h1>Agregados Familiares</h1>
              <button
                type="button"
                title="Adicionar Agregado"
                onClick={() => setDisplayModal(true)}
              >
                <img src={add} alt="plus" />
              </button>
            </Title>
            <Search>
              <input
                type="text"
                name="search"
                placeholder="Pesquisar..."
                onChange={handleSeach}
              />
              <div title="Pesquisar">
                <img src={search} alt="search" />
              </div>
            </Search>
          </Top>
          {isLoading ? (
            <Loading>
              <img src={loading} alt="Imagem de Loading" />
              <p>A carregar...</p>
            </Loading>
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <Th talign="center" width="5%">
                      #
                    </Th>
                    <Th talign="left" width="25%">
                      Nome
                    </Th>
                    <Th talign="center" width="10%">
                      Núm. Int
                    </Th>
                    <Th talign="center" width="18%">
                      Data Entrada
                    </Th>
                    <Th talign="center" width="18%">
                      Data Saída
                    </Th>
                    <Th talign="center" width="14%">
                      Nº de Agregados
                    </Th>
                    <Th talign="center" width="10%" />
                  </tr>
                </thead>
                <tbody>
                  {displayHouseholds.map((household, index) => (
                    <tr key={household.id}>
                      <Td talign="center" width="5%">
                        <Tdid>
                          {household.market_session && (
                            <img src={market} alt="search" />
                          )}
                          {index + 1}
                        </Tdid>
                      </Td>
                      <Td talign="left" width="25%">
                        {household.name}
                        {household.market_session &&
                        household.market_session.sessionType === '1' ? (
                          <img src={letras} alt="letras" />
                        ) : (
                          ''
                        )}
                        {household.market_session &&
                        household.market_session.sessionType === '2' ? (
                          <img src={numeros} alt="números" />
                        ) : (
                          ''
                        )}
                        {household.market_session &&
                        household.market_session.sessionType === '3' ? (
                          <img src={door} alt="porta" />
                        ) : (
                          ''
                        )}
                        {household.market_session &&
                        household.market_session.sessionType === '4' ? (
                          <>
                            <img src={letras} alt="letras" /> |
                            <img src={numeros} alt="números" />
                          </>
                        ) : (
                          ''
                        )}
                      </Td>
                      <Td talign="center" width="10%">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {household.int_num}{' '}
                          {household.food_support && (
                            <img
                              width="20px"
                              height="20px"
                              src={food_support}
                              alt="Apoio Alimentar"
                            />
                          )}
                        </div>
                      </Td>
                      <Td talign="center" width="18%">
                        {household.in_date ? (
                          <p>
                            {format(
                              parseISO(household.in_date),
                              `dd 'de' MMMM 'de' yyyy`,
                              {
                                locale: pt,
                              },
                            )}
                          </p>
                        ) : (
                          `Não definido`
                        )}
                      </Td>
                      <Td talign="center" width="18%">
                        {household.out_date ? (
                          <p>
                            {format(
                              parseISO(household.out_date),
                              `dd 'de' MMMM 'de' yyyy`,
                              {
                                locale: pt,
                              },
                            )}
                          </p>
                        ) : (
                          `Não definido`
                        )}
                      </Td>
                      <Td talign="center" width="14%">
                        {household.household_members
                          ? household.household_members?.length + 1
                          : 1}
                      </Td>
                      <Td talign="center" width="10%">
                        <div>
                          <button
                            type="button"
                            title="ver"
                            onClick={() => handleView(household.id)}
                          >
                            <img src={view} alt="view" />
                          </button>
                          <button
                            type="button"
                            title="editar"
                            onClick={() => handleEdit(household)}
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PageBox>
                <button
                  type="button"
                  title="anterior"
                  onClick={() => handlePage(-1)}
                >
                  <img src={page_left} alt="seta para a esquerda" />
                </button>
                <p>
                  {page} - {pages}
                </p>
                <button
                  type="button"
                  title="próximo"
                  onClick={() => handlePage(1)}
                >
                  <img src={page_right} alt="seta para a direita" />
                </button>
              </PageBox>

              <Modal visible={displayModal}>
                <ModalBox>
                  <h1>{editForm ? 'Editar Agregado' : 'Novo Agregado'}</h1>
                  <Close>
                    <button type="button" onClick={closeModal}>
                      &#x2715;
                    </button>
                  </Close>
                  <form onSubmit={handleSubmit(handleOnSave)}>
                    <Input
                      width="100%"
                      title="Nome do Agregado"
                      type="text"
                      {...register('name')}
                      error={errors.name?.message}
                    />
                    <Controller
                      control={control}
                      name="holder"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          title="Titular"
                          {...register('holder')}
                          onChange={onChange}
                          value={value}
                          error={errors.holder?.message}
                          options={optionUsers}
                        />
                      )}
                    />
                    <Input
                      width="100%"
                      title="Parentesco"
                      type="text"
                      {...register('kinship')}
                      error={errors.kinship?.message}
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'end',
                        marginTop: '10px',
                      }}
                    >
                      <CheckBox
                        width="auto"
                        title="Apoio Alimentar"
                        type="checkbox"
                        {...register('food_support')}
                        error={errors.food_support?.message}
                      />
                    </div>
                    <Button type="submit">
                      <img src={save} alt="Icon de desquete save" />
                      Guardar
                    </Button>
                  </form>
                </ModalBox>
              </Modal>
            </>
          )}
        </Widget>
      </Container>
    </>
  );
};

export default Household;
